














































  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { Auth } from '@one/types'

  @Component
  export default class OneLoginForm extends Vue {
    @Prop({
      type: String,
      required: true,
    })
    title!: string;

    @Prop({
      type: String,
      required: true,
    })
    buttonLabel!: string;

    @Prop({
      default: false,
    })
    progress!: boolean

    @Prop({
      default: () => ({
        email: '',
        password: '',
      }),
    })
    loginForm!: Auth.Requests.Login

    form: Auth.Requests.Login = {
      email: '',
      password: '',
    }

    showPassword: boolean = false

    @Watch('loginForm', { immediate: true })
    changeLoginForm() {
      this.form = this.loginForm
    }

    onSubmit(evt : any) {
      evt.preventDefault()
      this.$emit('login', this.form)
    }

    get passwordInputType(): string {
      return this.showPassword ? 'text' : 'password'
    }
  }
